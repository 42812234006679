.swiper {
    margin: 0px 32px;
    padding: 40px;
}

.swiper-slide {
    display: flex;
    justify-content: center;
}

.swiper-button-prev {
    color: #00C254;
}

.swiper-button-next {
    color: #00C254
}