/* ----------------------------------------------------- */
/* ---------------- Estilos genericos ------------------ */
/* ----------------------------------------------------- */
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

[hidden] {
  display: none;
}

.accordion__panel {
  animation: fadein 0.35s ease-in;
}

.selected-accordion-panel {
  overflow-y: auto;
}

.accordion__button:before {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(45deg);
}

/* ------------------------------------------------------- */
/* ---------------- Estilos das agendas ------------------ */
/* ------------------------------------------------------- */
.accordion-agendas .accordion__button,
.accordion-agendas .no-accordion-button {
  background-color: #ffffff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  text-align: left;
  border: none;
}

.accordion-agendas .no-accordion-button-selected {
  background-color: #447b89 !important;
  color: #ffffff;
}

.accordion-agendas .accordion__button:hover,
.accordion-agendas .no-accordion-button:hover {
  background-color: #ddd;
}

.accordion-agendas .accordion__button[aria-expanded="true"],
.accordion-agendas .accordion__button[aria-selected="true"] {
  background-color: #00c254;
  color: #ffffff;
}

.accordion-agendas .accordion-children .accordion__button.nivel-1,
.accordion-agendas .accordion-children .no-accordion-button.nivel-1 {
  background-color: #ededed;
}

.accordion-agendas .accordion-children .accordion__button.nivel-2,
.accordion-agendas .accordion-children .no-accordion-button.nivel-2 {
  background-color: #e9e9e9;
}

.accordion-agendas .accordion-children .accordion__button.nivel-3,
.accordion-agendas .accordion-children .no-accordion-button.nivel-3 {
  background-color: #e4e4e4;
}

.accordion-agendas .accordion-children .accordion__button:hover,
.accordion-agendas .accordion-children .no-accordion-button:hover {
  background-color: #ddd;
}

.accordion-agendas .accordion-children .accordion__button[aria-expanded="true"],
.accordion-agendas
  .accordion-children
  .accordion__button[aria-selected="true"] {
  background-color: #447b89;
  color: #ffffff;
}

.accordion-agendas
  .accordion-children
  .accordion__button[aria-expanded="true"].nivel-1 {
  background-color: #447b89e6;
  color: #ffffff;
}

.accordion-agendas
  .accordion-children
  .accordion__button[aria-expanded="true"].nivel-2 {
  background-color: #447b89cc;
  color: #ffffff;
}

.accordion-agendas
  .accordion-children
  .accordion__button[aria-expanded="true"].nivel-3 {
  background-color: #447b89b3;
  color: #ffffff;
}

/* ---------------------------------------------------------- */
/* ---------------- Estilos dos requisitos ------------------ */
/* ---------------------------------------------------------- */
.accordion-requisitos .accordion__button,
.accordion-requisitos .no-accordion-button {
  background-color: #cadce0;
  color: #414141;
  cursor: pointer;
  padding: 18px;
  text-align: left;
  border: none;
}

.accordion-requisitos .accordion__button:hover,
.accordion-requisitos .no-accordion-button:hover {
  background-color: #a9b9bc;
}

.accordion-requisitos .accordion__button[aria-expanded="true"],
.accordion-requisitos .accordion__button[aria-selected="true"] {
  background-color: #e9f3f5;
  color: #414141;
  font-weight: bold;
}

.accordion-requisitos .accordion__item {
  background-color: #e9f3f5;
  color: #414141;
}

.accordion-requisitos {
  margin-bottom: 16px;
}

.accordion-requisitos .accordion__panel {
  padding: 21px 10px 21px 16px;
}

.requisitos-title {
  font-weight: bold !important;
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.buttons-atividade {
  display: flex;
  justify-content: space-between;
}

/* ----------------------------------------------------------- */
/* ---------------- Animação dos accordions ------------------ */
/* ----------------------------------------------------------- */
@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
